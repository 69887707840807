import React from 'react';
import './footer.scss';
import { Link } from 'react-router-dom';
const Footer = () => {

    const scrolltop = () => {
        window.scrollTo(0, 0)
    }
    return (
        <>
            <section className="main-footer">
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-xl-10 col-lg-11 m-auto p-0">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="inner-logo">
                                        <img src="\assestmeta\LeocornEloin.png" alt="" className="bbbb" />

                                        <p>
                                            Leocorn is a launchpad-cum-VC under Eloin ecosystem, aims to empower the ideas of tomorrow.
                                        </p>
                                        {/* <div className="socisls">
                                            <div className="socinner">
                                                <a href="https://github.com/metafiofficial" target="_blank">
                                                    <div className="sbdsh">
                                                        <img src="\assestmeta\github-icon.svg" className="img-fluid" />
                                                        <i class="fab fa-github"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="socinner">
                                                <a href="https://twitter.com/metafi_official" target="_blank">
                                                    <div className="sbdsh" >
                                                        <img src="\assestmeta\twitter-icon.svg" className="img-fluid" />
                                                        <i class="fab fa-youtube"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="socinner">
                                                <a href="https://t.me/metafi_official" target="_blank">
                                                    <div className="sbdsh" >
                                                        <img src="\assestmeta\telegram-icon.svg" className="img-fluid" />
                                                        <i class="fab fa-youtube"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="socinner">
                                                <a href="https://medium.com/@metafiofficial" target="_blank">
                                                    <div className="sbdsh" >
                                                        <img src="\assestmeta\medium-icon.svg" className="img-fluid" />
                                                        <i class="fab fa-youtube"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="socinner">
                                                <a href="https://youtu.be/eWioWP6gb7E" target="_blank">
                                                    <div className="sbdsh" >
                                                        <img src="\assestmeta\youtube-icon.svg" className="img-fluid" />
                                                        <i class="fab fa-youtube"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="socinner">
                                                <a href=" https://instagram.com/metafi_official" target="_blank">
                                                    <div className="sbdsh">
                                                        <img src="\assestmeta\instagram-icon.svg" className="img-fluid" />

                                                        <i class="fab fa-instagram"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            

                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="inner-icon">
                                        {/* <h5 className="">Our Project </h5> */}
                                        <ul className="">
                                            <li>
                                                <a href="https://x.com/leocornpad" target="_blank" className="sd" to="#">
                                                    Twitter (Leocorn)
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/eloinclub" target="_blank" className="sd" to="#">
                                                    Twitter (Eloin)
                                                </a>
                                            </li>
                                            <li>
                                                {/* <Link className="sd" to="#" data-toggle="modal" data-target="#exampleModabb">
                                                    Roadmap
                                                </Link> */}
                                                <a href="https://t.me/eloinclub" target="_blank" className="sd" to="#">
                                                    Telegram (Eloin)
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://t.me/LeocornLabsANN" target="_blank" className="sd" to="#">
                                                    Telegram (Leocorn)
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://medium.com/@eloinproject" target="_blank" className="sd" to="#">
                                                    Medium
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://eloin.tech" className="sd" target="_blank" to="#">
                                                    Website (Eloin)
                                                </a>
                                            </li>
                                            <li>
                                                <address>
                                                    <a href="mailto:contact@eloin.tech" target="_blank" className="sd" to="#">
                                                        Contact Us
                                                    </a>
                                                </address>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <div className="col-sm-2">
                                    <div className="inner-icon">
                                        <h5 className="">TOKEN</h5>
                                        <ul className="">
                                            <li>
                                                <Link className="sd" to="#" data-toggle="modal" data-target="#exampleModalM">
                                                    Token Metrics
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="sd" to="#" data-toggle="modal" data-target="#exampleModalN">
                                                    Token Utilities
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="inner-icon">
                                        <h5 className="">Help</h5>
                                        <ul className="">
                                            <li>
                                                <a href="https://t.me/metafi_official" className="sd" to="#">
                                                    Contact Us
                                                </a>
                                            </li>
                                            <li>
                                                <a href="mailto:contact@meta-fi.app?subject=I have an Query" className="sd" to="#">
                                                    Feedback
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_blank" href="\assestmeta\Privacy Policy Metafi.pdf" className="sd" to="#">
                                                    Privacy Policy
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                            <div className="bacdropp">
                                <button className="bacdrop" onClick={scrolltop}>
                                    <img src="\assestmeta\Vector.png" className="img-fluid" />
                                    <h5>Back To Top</h5>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModalM" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                            <div class="modal-body">
                                <img src="/assestmeta/tokenomics-footer.jpeg" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModalN" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                            <div class="modal-body">
                                <img src="/assestmeta/utility-footer.jpeg" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModavv" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                            <div class="modal-body">
                                <img src="/assestmeta/feature-footer.jpeg" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="exampleModabb" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            {/* <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> */}
                            <div class="modal-body">
                                <img src="/assestmeta/WhatsApp Image 2022-02-04 at 9.57.08 PM.jpeg" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;
