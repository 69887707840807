import React from 'react';
import './css/animate.css';
import './css/style.css'
import './css/themify-icons.css'
import './css/font-awesome.min.css'
import { HashLink } from 'react-router-hash-link';
// import './js/main.js'
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
const TeamOur = () => {

    const owl_option = {
        // nav: false,
        dots: false,
        dotsEach: false,
        loop: true,
        autoplay: true,
        // navText: ["<i className='fas fa-chevron-left'></i>", "<i className='fas fa-chevron-right'></i>"],
        responsive: {
            0: {
                items: 1,

                margin: 15,
            },
            361: {
                items: 1,

                margin: 15,
            },
            600: {
                items: 1,

                margin: 15,
            },
            700: {
                items: 2,
                margin: 20,
            },
            1000: {
                items: 4,

                margin: 20,
            },
            1200: {
                items: 4,

                margin: 20,
            }
        },
    };
    const owl_optionss = {
        // nav: false,
        dots: false,
        dotsEach: false,
        loop: true,
        autoplay: true,
        // navText: ["<i className='fas fa-chevron-left'></i>", "<i className='fas fa-chevron-right'></i>"],
        responsive: {
            0: {
                items: 1,

                margin: 15,
            },
            361: {
                items: 1,

                margin: 15,
            },
            600: {
                items: 1,

                margin: 15,
            },
            700: {
                items: 2,
                margin: 20,
            },
            1000: {
                items: 2,

                margin: 20,
            },
            1200: {
                items: 2,

                margin: 20,
            }
        },
    };
    return (
        <>

            <header className="header-section clearfix">
                <div className="container-fluid">
                    <Link to="/" className="site-logo">
                        <img src="assestmeta/img/logo.png" alt="" />
                    </Link>
                    <div className="responsive-bar"><i className="fa fa-bars"></i>
                    </div>
                    <a href="https://meta-fi.app/" className="user"><i className="fa fa-arrow-circle-left"></i></a>
                    <a href="#" target="_blank" className="site-btn">BUY MetaFI</a>
                    <nav className="main-menu">
                        <ul className="menu-list">
                            <li> <HashLink to="#teams">Team</HashLink></li>
                            <li><HashLink to="#adv">Advisors</HashLink></li>

                            <li><a href="mailto:contact@meta-fi.app?subject=I have an Query">Contact</a></li>
                        </ul>
                    </nav>
                </div>
            </header>




            <section className="hero-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 hero-text">
                            <h2>Our Team <span>and</span> <br></br>Advisors</h2>
                            <h4>MetaFi is an immersive ecosystem for metaverse </h4>
                            <form className="hero-subscribe-from">
                                <input type="text" placeholder="Register or Contact for Job" />
                                <button className="site-btn sb-gradients"
                                    onClick={() => window.location = 'mailto:contact@meta-fi.app?subject=I want to join MetaFI community '}
                                    className="btn btn-lg btn-gradient-purple btn-glow float-right animated"
                                    data-animation="fadeInUpShorter" data-animation-delay="1.1s">Submit</button>
                            </form>
                        </div>
                        <div className="col-md-6">
                            <img src="assestmeta/img/laptop.png" className="laptop-image" alt="" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="team-section spad" id="teams">
                <div className="container">
                    <div className="section-title text-center">
                        <h2> Team </h2>
                        <p>Meet our diversified and enthusiastic team members!</p>
                    </div>
                </div>
                <div className="team-members clearfix">

                    <div className="member">
                        <div className="member-text">
                            <div className="member-img set-bg" data-setbg="img/member/1.png">
                                <img src="assestmeta/img/member/1.png" className="laptop-image" alt="" />
                            </div>
                            <h2>Aamir</h2>
                            <span>C.E.O & Co-founder</span>
                        </div>
                        <div className="member-social">
                            <a href="mailto:contact@meta-fi.app?subject=I have an Query"><i className="fa fa-envelope-o"></i></a>
                            <a href="https://t.me/aamir_mefi"><i className="fa fa-telegram"></i></a>
                            <a href="https://twitter.com/aamir_mefi"><i className="fa fa-twitter"></i></a>
                        </div>
                        <div className="member-info">
                            <div className="member-img mf set-bg" data-setbg="img/member/1.png">
                                <img src="assestmeta/img/member/1.png" className="laptop-image" alt="" />
                            </div>
                            <div className="member-meta">
                                <h2>Aamir</h2>
                                <span>C.E.O & Co-founder</span>
                            </div>
                            <p>“The value of an idea lies in the using of it.”</p>
                        </div>
                    </div>

                    <div className="member">
                        <div className="member-text">
                            <div className="member-img set-bg" data-setbg="img/member/2.png">
                                <img src="assestmeta/img/member/2.png" className="laptop-image" alt="" />
                            </div>
                            <h2>Faizan</h2>
                            <span>C.T.O & CO-founder</span>
                        </div>
                        <div className="member-social">
                            <a href="mailto:contact@meta-fi.app?subject=I have an Query"><i className="fa fa-envelope-o"></i></a>
                            <a href="https://t.me/mefi_mohammad"><i className="fa fa-telegram"></i></a>
                            <a href="https://twitter.com/mohammad_fai1"><i className="fa fa-twitter"></i></a>
                        </div>
                        <div className="member-info">
                            <div className="member-img mf set-bg" data-setbg="img/member/2.png">
                                <img src="assestmeta/img/member/2.png" className="laptop-image" alt="" />
                            </div>
                            <div className="member-meta">
                                <h2>Faizan</h2>
                                <span>C.T.O & CO-founder</span>
                            </div>
                            <p>“Chase the vision, not the money; the money will end up following you.”</p>
                        </div>
                    </div>

                    <div className="member">
                        <div className="member-text">
                            <div className="member-img set-bg" data-setbg="img/member/3.png">
                                <img src="assestmeta/img/member/3.png" className="laptop-image" alt="" />
                            </div>
                            <h2>Shashank</h2>
                            <span>Chief Dev & CO-founder</span>
                        </div>
                        <div className="member-social">
                            <a href="mailto:shashankkushwaha.sk321@gmail.com?subject=I have an Query"><i
                                className="fa fa-envelope-o"></i></a>
                            <a href="https://t.me/sushantkush"><i className="fa fa-telegram"></i></a>
                            <a href="https://twitter.com/KTechcollage"><i className="fa fa-twitter"></i></a>
                        </div>
                        <div className="member-info">
                            <div className="member-img mf set-bg" data-setbg="img/member/3.png">
                                <img src="assestmeta/img/member/3.png" className="laptop-image" alt="" />
                            </div>
                            <div className="member-meta">
                                <h2>Shashank</h2>
                                <span>Chief Dev & CO-founder</span>
                            </div>
                            <p>“Any time is a good time to start a company.”</p>
                        </div>
                    </div>

                    <div className="member">
                        <div className="member-text">
                            <div className="member-img set-bg" data-setbg="img/member/5.png">
                                <img src="assestmeta/img/member/5.png" className="laptop-image" alt="" />
                            </div>
                            <h2>Harsh</h2>
                            <span>C.M.O & CO-founder</span>

                        </div>
                        <div className="member-social">
                            <a href="mailto:srivastavaharsh57@gmail.com ?subject=I have an Query"><i
                                className="fa fa-envelope-o"></i></a>
                            <a href="https://t.me/harsh_mefi"><i className="fa fa-telegram"></i></a>
                            <a href="https://twitter.com/Harsh_mefi"><i className="fa fa-twitter"></i></a>
                        </div>
                        <div className="member-info">
                            <div className="member-img mf set-bg" data-setbg="img/member/5.png">
                                <img src="assestmeta/img/member/5.png" className="laptop-image" alt="" />
                            </div>

                            <div className="member-meta">
                                <h2>Harsh</h2>
                                <span>C.M.O & CO-founder</span>

                            </div>
                            <p>“High expectations are the key to absolutely everything.”</p>
                        </div>
                    </div>

                    <div className="member">
                        <div className="member-text">
                            <div className="member-img set-bg" data-setbg="img/member/4.png">
                                <img src="assestmeta/img/member/4.png" className="laptop-image" alt="" />
                            </div>
                            <h2>Waleed</h2>
                            <span>Full-Stack Dev</span>
                        </div>
                        <div className="member-social">
                            <a href="mailto:contact@meta-fi.app?subject=I have an Query"><i className="fa fa-envelope-o"></i></a>
                            <a href="#"><i className="fa fa-telegram"></i></a>
                            <a href="#"><i className="fa fa-twitter"></i></a>
                        </div>
                        <div className="member-info">
                            <div className="member-img mf set-bg" data-setbg="img/member/4.png">
                                <img src="assestmeta/img/member/4.png" className="laptop-image" alt="" />
                            </div>
                            <div className="member-meta">
                                <h2>Waleed</h2>
                                <span>Full-Stack Dev</span>
                            </div>
                            <p>“No man can be successful, unless he first loves his work.”</p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="section-title text-center">

                    </div>
                </div>
                <div className="team-members clearfix">

                    <div className="member">
                        <div className="member-text">
                            <div className="member-img set-bg" data-setbg="img/member/6.png">
                                <img src="assestmeta/img/member/6.png" className="laptop-image" alt="" />
                            </div>
                            <h2>Dean </h2>
                            <span>Strategy Lead</span>
                        </div>
                        <div className="member-social">
                            <a href="mailto:contact@meta-fi.app?subject=I have an Query"><i className="fa fa-envelope-o"></i></a>
                            <a href="#"><i className="fa fa-telegram"></i></a>
                            <a href="#"><i className="fa fa-twitter"></i></a>
                        </div>
                        <div className="member-info">
                            <div className="member-img mf set-bg" data-setbg="img/member/6.png">
                                <img src="assestmeta/img/member/6.png" className="laptop-image" alt="" />
                            </div>
                            <div className="member-meta">
                                <h2>Dean</h2>
                                <span>Strategy Lead</span>
                            </div>
                            <p>“Don’t worry about failure; you only have to be right once.”</p>
                        </div>
                    </div>

                    <div className="member">
                        <div className="member-text">
                            <div className="member-img set-bg" data-setbg="img/member/7.png">
                                <img src="assestmeta/img/member/7.png" className="laptop-image" alt="" />
                            </div>
                            <h2>Mary</h2>
                            <span>Social Media Manager</span>
                        </div>
                        <div className="member-social">
                            <a href="#"><i className="fa fa-envelope-o"></i></a>
                            <a href="https://t.me/maryannesantos"><i className="fa fa-telegram"></i></a>
                            <a href="https://twitter.com/freedomlifemary?s=21"><i className="fa fa-twitter"></i></a>
                        </div>
                        <div className="member-info">
                            <div className="member-img mf set-bg" data-setbg="img/member/7.png">
                                <img src="assestmeta/img/member/7.png" className="laptop-image" alt="" />
                            </div>
                            <div className="member-meta">
                                <h2>Mary</h2>
                                <span>Social Media Manager</span>
                            </div>
                            <p>“The heart and soul of a company is creativity and innovation.”</p>
                        </div>
                    </div>

                    <div className="member">
                        <div className="member-text">
                            <div className="member-img set-bg" data-setbg="img/member/8.png">
                                <img src="assestmeta/img/member/8.png" className="laptop-image" alt="" />
                            </div>
                            <h2>Krishna</h2>
                            <span>Partnerships Manager</span>
                        </div>
                        <div className="member-social">
                            <a href="mailto:contact@meta-fi.app?subject=I have an Query"><i className="fa fa-envelope-o"></i></a>
                            <a href="#"><i className="fa fa-telegram"></i></a>
                            <a href="#"><i className="fa fa-twitter"></i></a>
                        </div>
                        <div className="member-info">
                            <div className="member-img mf set-bg" data-setbg="img/member/8.png">
                                <img src="assestmeta/img/member/8.png" className="laptop-image" alt="" />
                            </div>
                            <div className="member-meta">
                                <h2>Krishna</h2>
                                <span>Partnerships Manager</span>
                            </div>
                            <p>“High expectations are the key to absolutely everything.”</p>
                        </div>
                    </div>
                    <div className="member">
                        <div className="member-text">
                            <div className="member-img set-bg" data-setbg="img/member/8.png">
                                <img src="assestmeta/img/member/WhatsApp Image 2022-02-06 at 3.47.47 PM.jpeg" className="laptop-image" alt="" />
                            </div>
                            <h2>Younis</h2>
                            <span>Product Growth Lead</span>
                        </div>
                        <div className="member-social">
                            <a href="mailto:contact@meta-fi.app?subject=I have an Query"><i className="fa fa-envelope-o"></i></a>
                            <a href="#"><i className="fa fa-telegram"></i></a>
                            <a href="#"><i className="fa fa-twitter"></i></a>
                        </div>
                        <div className="member-info">
                            <div className="member-img mf set-bg" data-setbg="img/member/8.png">
                                <img src="assestmeta/img/member/WhatsApp Image 2022-02-06 at 3.47.47 PM.jpeg" className="laptop-image" alt="" />
                            </div>
                            <div className="member-meta">
                                <h2>Younis</h2>
                                <span>Product Growth Lead</span>
                            </div>
                            <p>“High expectations are the key to absolutely everything.”</p>
                        </div>
                    </div>


                </div>
            </section>




            <section className="team-section spad" id="adv">
                <div className="container">
                    <div className="section-title text-center">
                        <h2> Advisors </h2>
                        <p>Meet our diversified and experienced advisors!</p>
                    </div>
                </div>

                <div className="member">
                    <div className="member-text">
                        <div className="member-img set-bg" data-setbg="img/member/10.jpeg">
                            <img src="assestmeta/img/member/10.jpeg" className="laptop-image" alt="" />
                        </div>
                        <h2>Amir Muhammad</h2>
                        <span>Founder & CEO at Okratech</span>
                    </div>
                    <div className="member-social">
                        <a href="#"><i className="fa fa-envelope-o"></i></a>
                        <a href="https://www.linkedin.com/in/kingamirkhan"><i className="fa fa-linkedin"></i></a>
                        <a href="#"><i className="fa fa-twitter"></i></a>
                    </div>
                    <div className="member-info">
                        <div className="member-img mf set-bg" data-setbg="img/member/10.jpeg">
                            <img src="assestmeta/img/member/10.jpeg" className="laptop-image" alt="" />
                        </div>
                        <div className="member-meta">
                            <h2>Amir Muhammad</h2>
                            <span>Founder & CEO at Okratech</span>
                        </div>
                        <p>“Your work is going to fill a large part of your life.”</p>
                    </div>
                </div>

                {/* <div className="member">
                    <div className="member-text">
                        <div className="member-img set-bg" data-setbg="img/member/9.jpeg">
                            <img src="assestmeta/img/member/WhatsApp Image 2022-02-06 at 3.47.46 PM.jpeg" className="laptop-image" alt="" />
                        </div>
                        <h2>Caglar Firat</h2>
                        <span>Community lead at Kommunitas.net</span>
                    </div>
                    <div className="member-social">
                        <a href="#"><i className="fa fa-envelope-o"></i></a>
                        <a href=" https://www.linkedin.com/in/caglarfirat24/"><i className="fa fa-linkedin"></i></a>
                        <a href="https://twitter.com/LOTZ_CF"><i className="fa fa-twitter"></i></a>
                    </div>
                    <div className="member-info">
                        <div className="member-img mf set-bg" data-setbg="\img/member/9.jpeg">
                            <img src="assestmeta/img/member/WhatsApp Image 2022-02-06 at 3.47.46 PM.jpeg" className="laptop-image" alt="" />
                        </div>
                        <div className="member-meta">
                            <h2>Caglar Firat</h2>
                            <span>Community lead at Kommunitas.net</span>
                        </div>
                        <p>“Without passion, you don’t have any energy.”</p>
                    </div>
                </div> */}

            </section>


        </>
    )
}

export default TeamOur;
