import React from 'react';
import './performance.scss';
import { Link } from 'react-router-dom';
const PerformanceM = () => {

    return (
        <>
            <section className="Performance">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 m-auto hdehdvh p-0">
                            <div className="imgdsssd dhbfjbfjb text-center">
                                <video
                                    className="video-player"
                                    muted="muted" playsinline="playsinline"
                                    autoPlay
                                    loop
                                    width="100%"
                                    class="supervideo shdbshdbshs"  >
                                    <source src="/assestmeta/ihj.mp4" type="video/mp4" />
                                </video>
                                <img src="\assestmeta\per1.png" alt="" className="img-fluid" />
                                <div className="sdkdkdn">
                                    <div className="left-side text-center mb-5">
                                        <h3>Apply For incubation</h3>
                                        <button className="text-white answdwds"  ><a className="text-white" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdRkAPW_zLgEFBqNvASjgPBqAYozeAkcG1tkVOdr5GLs3la8w/viewform?usp=sf_link">Apply Now</a></button>
                                        {/* <p>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-xl-9 col-lg-12 m-auto hdehdvh">

                            <div className="new-mmss">
                                <h3 className="text-center">SOCIALS</h3>
                                <div className="new-mms">
                                    <div className="ineerrleft">
                                        <div className="ss">
                                            <p>Twitter:</p>
                                            <p>Telegram ANN:</p>
                                            <p>Telegram: </p>
                                        </div>
                                        <div className="bb">
                                            <a href="https://twitter.com/metafi_official" target="_blank">
                                                <p>https://twitter.com/metafi_official</p>
                                            </a>
                                            <a href="https://t.me/metafi_ann" target="_blank"> <p>https://t.me/metafi_ann</p></a>
                                            <a href="https://t.me/metafi_official" target="_blank">
                                                <p>https://t.me/metafi_official</p></a>

                                        </div>
                                    </div>
                                    <div className="ineerrright">
                                        <div className="ss">
                                            <p>Medium: </p>
                                            <p>Instagram: </p>

                                        </div>
                                        <div className="bb">
                                            <a href="https://medium.com/@metafiofficial" target="_blank"><p>https://medium.com/@metafiofficial</p></a>
                                            <a href="https://instagram.com/metafi_official" target="_blank"><p> https://instagram.com/metafi_official</p></a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className="applynow">
                                <div className="snjasn">
                                    <div className="row">
                                        <div className='col-lg-6 col-md-5 col-12 sdhdh'>
                                            <div className="left-text">
                                                <h3>Apply for project incubation</h3>
                                                <p>Click on the link below to apply for incubation of your project</p>
                                               
                                            </div>
                                            <div className="sbd">
                                            <button type="button">
                                                    Apply Now
                                                </button>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-md-7 col-12 '>
                                            <div className="right-side">
                                                <h3>Socisls:</h3>
                                                <h4>Twitter: <a href="https://twitter.com/metafi_official" target="_blank">
                                                   <p>https://twitter.com/metafi_official</p> 
                                                    </a></h4>
                                                <h4>Telegram: <a href="https://t.me/metafi_official" target="_blank">
                                                    <p>https://t.me/metafi_official</p></a></h4>
                                                <h4>Telegram ANN: <a href="https://t.me/metafi_ann" target="_blank"> <p>https://t.me/metafi_ann</p></a></h4>
                                                <h4>Medium: <a href="https://medium.com/@metafiofficial" target="_blank"><p>https://medium.com/@metafiofficial</p></a></h4>
                                                <h4>Instagram: <a href="https://instagram.com/metafi_official" target="_blank"><p> https://instagram.com/metafi_official</p></a></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="upper-token">
                                <div className="imgdzss">
                                    <img src="\assestmeta\hg.png" alt="" className="img-fluid" />
                                </div>
                                <div className="upper-in">

                                    <div className="left">
                                        <img src="\assestmeta\metaf2.png" alt="" className="img-fluid" />
                                        <h5>MetaFI</h5>
                                    </div>
                                    <div className="right">
                                        <a href="#" target="_blank">
                                            <img src="\assestmeta\metaf3.png" alt="" className="img-fluid" />
                                        </a>
                                        <a href="https://t.me/metafi_official" target="_blank">
                                            <img src="\assestmeta\metaf4.png" alt="" className="img-fluid" />
                                        </a>
                                        <a href="https://twitter.com/metafi_official" target="_blank">
                                            <img src="\assestmeta\metaf5.png" alt="" className="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                                <div className="down-mm">
                                    <div className="jdsjdbn">
                                        <div className="sjdb">
                                            <h6>Current Price</h6>
                                            <h5>$167.338 <span>^11.624%</span></h5>
                                        </div>
                                    </div>
                                    <div className="anadn">
                                        <div className="sjdbxc">
                                            <h6>Market Cap:  <span>$123M</span></h6>
                                            <h5>Vol (24h):<span>$8.9M</span><span className="sj">^8%</span></h5>
                                        </div>
                                        <div className="sjdbxcxds">
                                            <h6>Raised:<span>$578K </span></h6>
                                            <h5>IDO Price:<span>$1.2</span></h5>
                                        </div>
                                        <div className="sjdbxcdsds">
                                            <h6>IDO ROI:<span>167.338x</span></h6>
                                            <h5>Native Token:<span> <img src="\assestmeta\metmm1.png" alt="" className="img-fluid" />MEFI</span></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="downtable">
                                <div className="tabless">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">RANK <i class="fas fa-sort ml-2"></i></th>
                                                    <th scope="col">Name <i class="fas fa-sort ml-2"></i></th>
                                                    <th scope="col">Price <i class="fas fa-sort ml-2"></i></th>
                                                    <th scope="col">Chg 24H <i class="fas fa-sort ml-2"></i></th>
                                                    <th scope="col">Chg 7D <i class="fas fa-sort ml-2"></i></th>
                                                    <th scope="col">Market Cap <i class="fas fa-sort ml-2"></i></th>
                                                    <th scope="col">Vol (24H) <i class="fas fa-sort ml-2"></i></th>
                                                    <th scope="col">IDO ROI <i class="fas fa-sort ml-2"></i></th>
                                                </tr>
                                            </thead>
                                            <tbody className="asjsj">
                                                <tr>
                                                    <td>321</td>
                                                    <td className="sjdh"><img src="\assestmeta\mett1.png" alt="" className="img-fluid" /> <span>SHILL</span></td>
                                                    <td>$167.338</td>
                                                    <td className="sj">^11.624%</td>
                                                    <td className="sjred"><small>v</small>15.624%</td>
                                                    <td>$123M</td>
                                                    <td>$8.9M</td>
                                                    <td>167.338x</td>
                                                </tr>
                                                <tr>
                                                    <td>322</td>
                                                    <td className="sjdh"><img src="\assestmeta\mtt4.png" alt="" className="img-fluid" /> <span>SHILL</span></td>
                                                    <td>$167.338</td>
                                                    <td className="sj">^10.624%</td>
                                                    <td className="sj"><small>v</small>12.624%</td>
                                                    <td>$123M</td>
                                                    <td>$8.9M</td>
                                                    <td>167.338x</td>
                                                </tr>
                                                <tr>
                                                    <td>323</td>
                                                    <td className="sjdh"><img src="\assestmeta\mtt5.png" alt="" className="img-fluid" /> <span>SHILL</span></td>
                                                    <td>$167.338</td>
                                                    <td className="sjred"><small>v</small>11.624%</td>
                                                    <td className="sj">^17.624%</td>
                                                    <td>$123M</td>
                                                    <td>$8.9M</td>
                                                    <td>167.338x</td>
                                                </tr>
                                                <tr>
                                                    <td>324</td>
                                                    <td className="sjdh"><img src="\assestmeta\mtt6.png" alt="" className="img-fluid" /> <span>SHILL</span></td>
                                                    <td>$167.338</td>
                                                    <td className="sjred"><small>v</small>5.624%</td>
                                                    <td className="sjred"><small>v</small>10.624%</td>
                                                    <td>$123M</td>
                                                    <td>$8.9M</td>
                                                    <td>167.338x</td>
                                                </tr>
                                                <tr>
                                                    <td>325</td>
                                                    <td className="sjdh"><img src="\assestmeta\mtt7.png" alt="" className="img-fluid" /> <span>SHILL</span></td>
                                                    <td>$167.338</td>
                                                    <td className="sjred">^11.624%</td>
                                                    <td className="sjred">^15.624%</td>
                                                    <td>$123M</td>
                                                    <td>$8.9M</td>
                                                    <td>167.338x</td>
                                                </tr>
                                                <tr>
                                                    <td>326</td>
                                                    <td className="sjdh"><img src="\assestmeta\mtt8.png" alt="" className="img-fluid" /> <span>SHILL</span></td>
                                                    <td>$167.338</td>
                                                    <td className="sj">^11.624%</td>
                                                    <td className="sj">^15.624%</td>
                                                    <td>$123M</td>
                                                    <td>$8.9M</td>
                                                    <td>167.338x</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                         */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PerformanceM;
