import React, { useState, useEffect, useCallback } from 'react';
import './unstacking.scss';
import { Link } from 'react-router-dom';
import { Backdrop } from "@material-ui/core";
// import ClaimWithdrawal from "../../hooks/claimStaking";
import { useWeb3React } from "@web3-react/core";
import swal from 'sweetalert';
import {
    useUnStake,
    StakedAmount,
    UnStakedAmount,
    StakedAmountReward,
    RewardPerSec,
    ClaimReward,
    UseAllTokenAPY,
    TotalStakedAmount
} from "../../hooks/dataFetcher";
const UnStacking = () => {
    const { account } = useWeb3React();
    const [unstackbalance, setunstackbalance] = useState("");
    const [unlockDate, setunlockDate] = useState("");
    const [stakedTime, setStakedTime] = useState("");
    const apy = UseAllTokenAPY();
    const [currentTIme, setCurrentTIme] = useState(0);
    const [totalpercentage, settotalpercentage] = useState(0);
    const [rewardSec, setRewardSec] = useState(0);
    const { UnStakeToken } = useUnStake();
    const totalUnStaked = UnStakedAmount()
    const { Claimed } = ClaimReward();
    const totalStaked = StakedAmount();
    const reward = StakedAmountReward()
    const rewardPerSec = RewardPerSec()
    const totalStakeAmount=TotalStakedAmount()
    //   const totalUnStaked = 121212
    // const totalStaked = 121212
    // const reward = 1212112
    // console.log("reward", totalUnStaked)
    const [opens, setOpens] = useState(false);
    
    const UnStake_Token = useCallback(async () => {
        if (parseInt(totalUnStaked.stakedAmount) > 0) {
            try {
                // setShowLoader(true)
                setOpens(true);
                const tx = await UnStakeToken();
                if (tx.status) {
                    // await setTxstatus(tx.status);
                    await setunstackbalance("");
                    await settotalpercentage(0)
                    setOpens(false);
                    closeeeee()
                    swal("Successfully UnStaked", ``, "success",)
                    // window.location.reload()
                    // await getUnstakedBalance();
                    // setShowLoader(false)
                    // setInputBusd('')
                    // close()
                }
            } catch (err) {
                setOpens(false);
                // setShowLoader(false)
                console.log("err22", err);
            }
        } else {
            swal("Error!!!", `Unable To UnStake:::::::`, "error",)
        }

    });

    const userWithdrawal = async () => {
        if (account && parseInt(totalUnStaked.stakedAmount) > 0) {
            try{
                setOpens(true);
                let res = await Claimed();
                if(res){
                    swal("Successfully withdraw", ``, "success",)
                    setOpens(false);
                }
          
            }catch(err){
                setOpens(false);
                console.log("widraw error",err)
                swal({
                    title: 'Error!',
                    text: err.message, // Assuming the error message is stored in the 'message' property of the error object
                    icon: 'error',
                    button: 'OK',
                  });
            }
        }   else {
            setOpens(false);
            swal("Withdraw Error!!!", `No Amount To withdraw`, "error",)
        }

        // setWithdrawIndex(2)
    };

    const handleInputs = (e) => {
        let a = (e.target.value / totalStaked) * 100
        settotalpercentage(parseFloat(a).toFixed(1))
        // console.log("asdsadasdsa",parseFloat(a).toFixed(1))
        const input = e.target.value;
        setunstackbalance(input);
    };

    const twentyFive = () => {
        settotalpercentage(25)
        let a = (25 * totalStaked) / 100
        setunstackbalance(parseFloat(a).toFixed(0));
    }

    const fifty = () => {
        settotalpercentage(50)
        let a = (50 * totalStaked) / 100
        setunstackbalance(parseFloat(a).toFixed(0));
    }

    const seventyFive = () => {
        settotalpercentage(75)
        let a = (75 * totalStaked) / 100
        setunstackbalance(parseFloat(a).toFixed(0));
    }

    const Full = () => {
        settotalpercentage(100)
        setunstackbalance(parseFloat(totalStaked).toFixed(0));
    }

    // useEffect(() => {

        let date = new Date(totalUnStaked?.releaseTime * 1000)
        var date2 = date?.toLocaleString();
        let UNnlockdate=date2
        // setunlockDate(date2)
    let c=new Date(totalUnStaked?.stakedTime * 1000)
        var date3 = c?.toLocaleString();
        let Stakedtime=date3
        // setStakedTime(date3)

        const currentTimestampMillisecondsAlt = new Date().getTime();
        const currentTimestampSecondsAlt = Math.floor(currentTimestampMillisecondsAlt / 1000) 
        let a = currentTimestampSecondsAlt - parseInt(totalUnStaked?.lastClaimTime)
        let b = (rewardPerSec* a)/10**18
        let Reward=b
        console.log("dataaa",currentTimestampSecondsAlt,totalUnStaked.releaseTime)
        // setRewardSec(b)
        // console.log("loggggg",b);
        // setCurrentTIme(currentTimestampSecondsAlt)
    // }, [totalUnStaked]);



    const opeeennn = () => {
        if (account && parseInt(totalUnStaked.stakedAmount) > 0) {
            window.$('#exampleModal').modal('show')
        } else {
            swal("Error!!!", `Please Stake First`, "error",)
        }
    }
    const closeeeee = () => {
        window.$('#exampleModal').modal('hide')
    }
    return (
        <>
            <Backdrop className="loader" sx={{ color: "#fff" }} open={opens}>
                <img
                       src="/assestmeta/Eloin.svg"
                    alt=""
                    className="img-fluid shdshhgdss"
                />
            </Backdrop>
            <section className="Unstacking">
                {/* <a href = '/'>staking</a> */}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-10 col-lg-11 m-auto hdehdvh">
                            <div className="stakingmain">
                                <div className="searcjpper">
                                    <div className="left">
                                        <div className="finished">
                                            {/* <button className='but1'>Live</button> */}
                                            {/* <button className='but2'>Finished</button> */}
                                        </div>
                                        {/* <div className="toggler-main">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="customSwitches" />
                                                <label class="custom-control-label" for="customSwitches">My Staking Pools</label>
                                            </div>
                                        </div> */}

                                    </div>
                                    {/* <div className="right">
                                        <div className="inputxx">
                                            <input class="form-control mr-sm-2" type="search" placeholder="Search by pool name" aria-label="Search" />
                                            <img src="\assestmeta\search-normal.svg" alt="" className="img-fluid shbdhd" />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="stackingdown">
                                    <div className="metifistak">
                                        <div className='left'>
                                            <div className="imhd">
                                                <img src="\assestmeta\Eloin.svg" alt="" className="img-fluid shbdhd sggg" />
                                                <div className="textss">
                                                    <h3>Eloin Staking Pool </h3>
                                                    {/* <h5>With IGO</h5> */}
                                                </div>
                                            </div>
                                            <div className="rihgtsss">
                                                <div className="upper">
                                                    <div className="inner">
                                                        <div className="iconsss">
                                                            <img src="\assestmeta\sta15.svg" alt="" className="img-fluid shbdhd" />
                                                            <h6>Rookie</h6>
                                                        </div>
                                                        <h3>{apy[0]?.lockDays} Days</h3>
                                                    </div>
                                                </div>
                                                <div className="upper">
                                                    <div className="inner">
                                                        <div className="iconsss">
                                                            <img src="\assestmeta\sta12.svg" alt="" className="img-fluid shbdhd" />
                                                            <h6>Pro</h6>
                                                        </div>
                                                        <h3>{apy[1]?.lockDays} Days</h3>
                                                    </div>
                                                </div>
                                                <div className="upper">
                                                    <div className="inner">
                                                        <div className="iconsss">
                                                            <img src="\assestmeta\sta14.svg" alt="" className="img-fluid shbdhd" />
                                                            <h6>Elite</h6>
                                                        </div>
                                                        <h3>{apy[2]?.lockDays} Days</h3>
                                                    </div>
                                                </div>
                                                <div className="upper">
                                                    <div className="inner">
                                                        <div className="iconsss">
                                                            <img src="\assestmeta\sta13.svg" alt="" className="img-fluid shbdhd" />
                                                            <h6>Legend</h6>
                                                        </div>
                                                        <h3>{apy[3]?.lockDays} Days</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='right'>
                                            <div className="mainsss">
                                                {/* <h4>Details <i class="fas fa-chevron-up"></i></h4> */}
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="alertss">Note: NEVER transfer coin/token directly to smart contract. All transactions need to go through Eloin's Portal.</h5>
                                    <div className="border-djds">
                                        <div className="upper-vlck">
                                            <div className="left-side">
                                                <div className="left">
                                                    <h6>Total pool amount</h6>
                                                    <h6>Amount Staked</h6>
                                                    <h6>Staking Time</h6>
                                                    <h6>Release Time</h6>
                                                    <h6>APY</h6>
                                                    <h6>Lock Days</h6>
                                                    <h6>Total Reward Claimed</h6>
                                                </div> 
                                                <div className="right">
                                                    <h6>{totalStakeAmount ? totalStakeAmount : 0}</h6>
                                                    <h6>{totalUnStaked ? totalUnStaked.stakedAmount / 10**18 : 0} Eloin</h6>
                                                    <h6>{Stakedtime ? Stakedtime : 0}</h6> 
                                                    <h6>{UNnlockdate ? UNnlockdate : 0}</h6>
                                                    <h6>{totalUnStaked ? totalUnStaked.rewardPercentage /10 : 0}%</h6>
                                                    <h6>{totalUnStaked ? totalUnStaked.daysLocked : 0} Days</h6>
                                                    <h6>{totalUnStaked ? (totalUnStaked.rewardClaimed / 10**18).toFixed(6) : 0}</h6>
                                                </div>
                                            </div>
                                            <div className="right-side">
                                                <h6>Staked</h6>
                                                <h5>{totalUnStaked ? totalUnStaked.stakedAmount / 10 ** 18 : 0} Eloin</h5>
                                                {/* <h5>0 Eloin</h5> */}
                                                <div className="buttonssss">
                                                    {/* <button className="hg">
                                                        Stake
                                                    </button> */}
                                                    { currentTimestampSecondsAlt > totalUnStaked.releaseTime ?
                                                    <button className="hg" onClick={UnStake_Token}>
                                                        Unstake
                                                    </button>
                                                    :
                                                    <button className="hgg" >
                                                        Unstake
                                                    </button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="right-sideee">
                                                <div className="upper">
                                                    <h6>Claimable Amount</h6>
                                                    <h5>{Reward ? Reward : 0} Eloin</h5>
                                                </div>
                                              
                                                {/* <h5>0 Eloin</h5> */}
                                                <div className="buttonssss">
                                                    <button className="hg" onClick={() => userWithdrawal()}>
                                                        Claim Rewards
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="live">
                                        <div className="buttonssdk">
                                            <button className="bn">Realtime</button>
                                        </div>
                                        <div class="textsssss">
                                            <h3>Legendary Ranking Realtime</h3>
                                        </div>
                                        <div className="tbaless">
                                            <div class="table-responsive shbshbcs">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">No</th>
                                                            <th scope="col">Wallet Address</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Last Time Staked</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta6.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>1</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta7.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>2</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta8.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>3</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta6.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>4</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta7.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>5</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta8.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>6</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta6.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>7</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta7.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>8</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta8.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>9</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <div className="newoness">
                                                    <h5>Total Eloin</h5>
                                                    <h5>27,443.798</h5>
                                                </div>
                                            </div>
                                        </div>

                                    </div> */}

                                    {/* <div className="finished">
                                        <div className="buttonssdk">
                                            <button className="bn">Realtime</button>
                                            <button className="nn">Snapshot</button>
                                        </div>
                                        <div class="textsssss">
                                            <h3>Legendary Ranking Realtime</h3>
                                            <h4>Details <i class="fas fa-chevron-up"></i></h4>
                                        </div>
                                        <div className="tbaless">
                                            <div class="table-responsive shbshbcs">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">No</th>
                                                            <th scope="col">Wallet Address</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Last Time Staked</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta6.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>1</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta7.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>2</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta8.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>3</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta6.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>4</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta7.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>5</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta8.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>6</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta6.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>7</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta7.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>8</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta8.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>9</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <div className="newoness">
                                                    <h5>Total Eloin</h5>
                                                    <h5>27,443.798</h5>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                 */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade untake-modalsss" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">UnStake</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body jsbdjwdb">
                                <div className="untake-mpdal">
                                    <div className="untakk">
                                        <div className="left">
                                            <h5>Token</h5>
                                            <h5>Staked</h5>
                                            <h5>Current Profit</h5>
                                        </div>
                                        <div className="right">
                                            <h5>Eloin</h5>
                                            <h5>{totalStaked ? totalStaked : 0}</h5>
                                            <h5>{reward ? reward : 0}</h5>
                                        </div>
                                    </div>
                                    <div className="inpiuttss">
                                        <h5>Untake Amount</h5>
                                        <input type="number" class="form-control" value={unstackbalance} placeholder="0" onChange={handleInputs} />
                                    </div>
                                    <div className="proggrd">
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" style={{ width: `${totalpercentage}%` }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <h5>({totalpercentage}%)</h5>
                                    </div>
                                    <div className="main-percent">
                                        <div className="hh" onClick={twentyFive}>
                                            <h5>25%</h5>
                                        </div>
                                        <div className="hh mr-2 ml-2" onClick={fifty}>
                                            <h5>50%</h5>
                                        </div>
                                        <div className="hh mr-2" onClick={seventyFive}>
                                            <h5>75%</h5>
                                        </div>
                                        <div className="hh" onClick={Full}>
                                            <h5>100%</h5>
                                        </div>
                                    </div>
                                    <button className="shvdsj" onClick={UnStake_Token}>Unstake</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default UnStacking;
