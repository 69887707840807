import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import useWeb3 from './useWeb3'
import { getContractStake, TokenContract, getSeed } from '../utils/contractHelpers.js'
import { getFullDisplayBalance, getBalanceNumber } from '../utils/formatBalance'
import useRefresh from './useRefresh'
import Environment from '../utils/Environment'

const stakeContract = Environment.stakingcontractAddress;
const tokenContract = Environment.tokenAddress;
const seedContract = Environment.seedContractAddress;

const useApprove = () => {
    const web3 = useWeb3();
    const { account } = useWeb3React();
    try {
        const ApproveTokens = useCallback(async () => {
            if(web3){
                let gasPrice = await web3.eth.getGasPrice();
                const contract = TokenContract(tokenContract, web3)
                let nd = web3.utils.toWei('10000000000000000000000000000'.toString(), "ether");
                // console.log("conc",contract)
                const gas =await contract.methods.approve(stakeContract, nd).estimateGas({from: account})
                const approved = await contract.methods.approve(stakeContract, nd).send({ from: account,gas, gasPrice:gasPrice })
                return approved
            }
         
        })

        return { ApproveTokens }
    } catch (err) {
        console.log("approve err", err)
        throw err
    }
}

const CheckAllowance = () => {
    const [balance, setBalance] = useState()
    const { account } = useWeb3React()
    const web3 = useWeb3()
    const contract = TokenContract(tokenContract, web3)
    useEffect(() => {
        const fetchBalance = async () => {
            if (!account) {
                setBalance(0);
                return;
            }
            let tallow = await contract.methods.allowance(account, stakeContract).call()
            let allowance = tallow / 10**18
            // let allowance = getBalanceNumber(parseInt(tallow), 18)
            // console.log("avsvvsvs",allowance, tallow)
            // setBalance(new BigNumber(balance))
            await setBalance(allowance)
        }

        if (account) {
            fetchBalance()
        }
    }, [account,contract])

    return balance
}

const StakedAmount = () => {
    const web3 = useWeb3()
    const [balance, setBalance] = useState(0)
    const contract = getContractStake(stakeContract, web3);
    const { account } = useWeb3React();
    useEffect(() => {
        const fetchBalance = async () => {
            if (!account) {
                setBalance(0);
                return;
            }
            // const tallow = await contract.methods.userInfo(0, account).call()
            // setBalance((tallow.amount / 10 ** 18).toFixed(0));
        }

        if (account) {
            fetchBalance()
        }
    }, [account,contract])
    return balance
}

const StakedAmountReward = () => {
    const web3 = useWeb3()
    const [balance, setBalance] = useState(0)
    const contract = getContractStake(stakeContract, web3);
    const { account } = useWeb3React();
    useEffect(() => {
        const fetchBalance = async () => {
            if (!account) {
                setBalance(0);
                return;
            }
            const tallow = await contract.methods.getRewards(account).call()
            // console.log("re",tallow);
            setBalance(tallow / 10 ** 18);
        }

        if (account) {
            fetchBalance()
        }
    }, [account])
    return balance
}

const TotalStakedAmount = () => {
    const web3 = useWeb3()
    const [balance, setBalance] = useState(0)
    const contract = getContractStake(stakeContract, web3);
    const { account } = useWeb3React();
    useEffect(() => {
        const fetchBalance = async () => {
            if (!account) {
                setBalance(0);
                return;
            }
            const tallow = await contract.methods.totalStakedAmount().call()
            // console.log("re",tallow);
            setBalance(tallow / 10 ** 18);
        }

        if (account) {
            fetchBalance()
        }
    }, [contract])
    return balance
}


// export default StakedAmount;

const UnStakedAmount = () => {
    const web3 = useWeb3()
    const [balance, setBalance] = useState(0)
    const contract = getContractStake(stakeContract, web3);
    const { account } = useWeb3React();
    useEffect(() => {
        const fetchBalance = async () => {
            // if (!account) {
            //     setBalance(0);
            //     return;
            // }
            const tallow = await contract.methods.users(account).call()
            setBalance(tallow);
        }

        if (account) {
            fetchBalance()
        }
    }, [account,contract])
    return balance
}

const RewardPerSec = () => {
    const web3 = useWeb3()
    const [balance, setBalance] = useState(0)
    const contract = getContractStake(stakeContract, web3);
    const { account } = useWeb3React();
    useEffect(() => {
        const fetchBalance = async () => {
            if (!account) {
                setBalance(0);
                return;
            }
            const tallow = await contract.methods.calculateRewardPerSecond(account).call()
            setBalance(tallow);
        }

        if (account) {
            fetchBalance()
        }
    }, [account])
    return balance
}

// export default UnStakedAmount;



const UseTokenBalance = () => {
    const [balance, setBalance] = useState(0)
    const { account } = useWeb3React()
    const web3 = useWeb3()
    const contract = TokenContract(tokenContract, web3)
    useEffect(() => {
        if (!account) {
            setBalance(0);
            return;
        }
        const fetchBalance = async () => {
            try {
                let balance = await contract.methods.balanceOf(account).call()
                // console.log("herer",balance,account)
                setBalance(parseInt(balance) / 10 ** 18);
            } catch (error) {
                console.log("errr",error)
                setBalance(0);
            }
        }

        // if (account) {
        fetchBalance()
        // }
    }, [account, contract])

    return balance
}


const UseAllTokenAPY= () => {
    const [balance, setBalance] = useState(0)
    const dataArray = [];
    const { account } = useWeb3React()
    const web3 = useWeb3()
    const contract = getContractStake(stakeContract, web3)
    useEffect(() => {
        const fetchBalance = async () => {
            try {
                for (let index = 0; index <= 3; index++) {
                    // const lockDays = 15 + index; // Example calculation for lockDays
                    // const percentage = 2 + index; // Example calculation for percentage
                    let balance = await contract.methods.apy(index).call()
                    // console.log("herer",balance)
                    const data = {
                        lockDays:balance.lockDays,
                        percentage:balance.percentage,
                    };
                    // Create an object with the generated data
                    
                    // Push the object into the dataArray
                    dataArray.push(data);
                }
                // console.log("data",dataArray)
                setBalance(dataArray);
           
            } catch (error) {
                console.log("errr",error)
                setBalance(0);
            }
        }

        // if (account) {
        fetchBalance()
        // }
    }, [account, contract])

    return balance
}


const useStake = (amount,locktier) => {
    const { account } = useWeb3React();
    const web3 = useWeb3();
    const contract = getContractStake(stakeContract, web3)
    // const ammnt= amount.toString()
    // const amount1 = web3.utils.toWei(amount,'ether');
    // const amount1 = new BigNumber(amount).multipliedBy(
    //     new BigNumber(10).pow(18)
    //   );
    // const amount1 = (amount * (10 ** 18))
    // console.log("amounnnttt", amount, account)
    const StakePsr = useCallback(async () => {
        try {
            let gasPrice = await web3.eth.getGasPrice();
            const gas =await contract.methods.stake(web3.utils.toWei(amount.toString(), 'ether'),locktier).estimateGas({from: account})
            const approved = await contract.methods.stake(web3.utils.toWei(amount.toString(), 'ether'),locktier).send({ from: account,gas,gasPrice:gasPrice  })
            return approved
        } catch (error) {
            console.log('error:÷:::::', error)
            throw error;
        }
    }, [account, amount,web3])

    return { Stake: StakePsr }
}

const ClaimReward = () => {
    const { account } = useWeb3React();
    const web3 = useWeb3();
    const contract = getContractStake(stakeContract, web3)
    // const ammnt= amount.toString()
    // const amount1 = web3.utils.toWei(amount,'ether');
    // const amount1 = new BigNumber(amount).multipliedBy(
    //     new BigNumber(10).pow(18)
    //   );
    // const amount1 = (amount * (10 ** 18))
    // console.log("amounnnttt", amount, account)
    const StakePsr = useCallback(async () => {
        try {
            let gasPrice = await web3.eth.getGasPrice();
            const gas =await contract.methods.claim().estimateGas({from: account})
            const approved = await contract.methods.claim().send({ from: account,gas,gasPrice:gasPrice})
            return approved
        } catch (error) {
            console.log('error:÷:::::', error)
            throw error;
        }
    }, [account,web3])

    return { Claimed: StakePsr }
}
const useUnStake = (amount) => {
    const { account } = useWeb3React();
    const web3 = useWeb3();
    const contract = getContractStake(stakeContract, web3)
    // console.log("asdjflasdjf",typeof amount)
    // const amount1= amount;
    //  const amount1 = web3.utils.toWei(amount.toString(),'ether');

    // console.log("===========>",amount1)
    const UnStakePsr = useCallback(async () => {
        // console.log("amounnntttunnnnnnn", amount, account)
        try {
            const approved = await contract.methods.unStake().send({ from: account })
                .on('transactionHash', (tx) => { return tx.transactionHash });
            return approved
        } catch (error) {
            console.log(
                'error::::::', error
            )
            throw error;
        }
    }, [account, web3])

    return { UnStakeToken: UnStakePsr }
}
export default UseTokenBalance

export { useStake, useUnStake, useApprove, ClaimReward,CheckAllowance, UnStakedAmount, StakedAmount, UseTokenBalance, StakedAmountReward,UseAllTokenAPY,RewardPerSec ,TotalStakedAmount};