import React, { useState, useEffect, useCallback } from 'react';
import './stacking.scss';
import { Link } from 'react-router-dom';
import useWeb3 from "../../hooks/useWeb3";
import swal from 'sweetalert';
import { Backdrop } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { CircularProgress } from '@material-ui/core';
import { EarnApy } from "../../hooks/earnApy";
import Environment from "../../utils/Environment";
import axios from "axios";
import {
    UseTokenBalance,
    CheckAllowance,
    useStake,
    useUnStake,
    useApprove,
    StakedAmount,
    UnStakedAmount,
    StakedAmountReward,
    UseAllTokenAPY, TotalStakedAmount
} from "../../hooks/dataFetcher";

const Stacking = () => {
    const web3 = useWeb3()
    // const account=
    const { account } = useWeb3React();
    const [update, setupdate] = useState(0);
    const [updatedata, setupdatedata] = useState();
    const [stakedData, setStakedData] = useState([]);
    const [updatedata1, setupdatedata1] = useState('a');
    const [stackbalance, setstackbalance] = useState("");
    const [totalpercentage, settotalpercentage] = useState(0);
    const [myApy, setMyApy] = useState("");
    const [opens, setOpens] = useState(false);
    // console.log("stack ",stackbalance)
    const balance = UseTokenBalance();
    const totalStakeAmount = TotalStakedAmount()
    const allowance = CheckAllowance();
    const { apyAmount } = EarnApy();
    const apy = UseAllTokenAPY();
    // const totalUnStaked = UnStakedAmount()
    // console.log("alow",allowance,balance)
    const { ApproveTokens } = useApprove();
    const { Stake } = useStake(stackbalance, update);
    const Approve_Fun = async () => {
        if (account) {
            try {
                setOpens(true);
                const tx = await ApproveTokens(account);
                if (tx.status) {
                    setOpens(false)
                    window.location.reload()
                }
            } catch (err) {
                setOpens(false);
                console.log("error in approve ::::", err);
                return err;
            }
        }
    };

    const Stake_token = useCallback(async () => {
        if (allowance > 0 && allowance > stackbalance && stackbalance > 0) {
            try {
                // setShowLoader(true)
                setOpens(true);
                const tx = await Stake();
                if (tx.status) {
                    // await setTxstatus(tx.status);
                    await setstackbalance("");
                    settotalpercentage(0)
                    setOpens(false);
                    swal("Successfully Staked", ``, "success",)
                    window.localStorage.reload()
                    // await getStakedbalance();
                    // setShowLoader(false)
                    // setInputBusd('')
                    // close()

                }
            } catch (err) {
                setOpens(false);
                // setShowLoader(false)
                console.log("err22", err);
            }
        } else {
            swal("Error!!!", `Unable To Stake`, "error",)
        }

    });

    const handleInput = (e) => {
        let a = (e.target.value / balance) * 100
        settotalpercentage(parseFloat(a).toFixed(1))
        const input = e.target.value;
        setstackbalance(input);
    };

    const twentyFive = (data) => {
        setupdatedata(data ? data : 'a')
        settotalpercentage(25)
        let a = (25 * balance) / 100
        setstackbalance(parseFloat(a).toFixed(0));
    }

    const fifty = (data) => {
        setupdatedata(data)
        settotalpercentage(50)
        let a = (50 * balance) / 100
        setstackbalance(parseFloat(a).toFixed(0));
    }

    const seventyFive = (data) => {
        setupdatedata(data)
        settotalpercentage(75)
        let a = (75 * balance) / 100
        setstackbalance(parseFloat(a).toFixed(0));
    }

    const Full = (data) => {
        setupdatedata(data)
        settotalpercentage(100)
        setstackbalance(parseFloat(balance).toFixed(0));
    }
    const Fullm = (data) => {
        setupdatedata1(data)


    }
    const Fullmm = (data) => {
        setupdatedata1(data)


    }
    const Fullmmm = (data) => {
        setupdatedata1(data)


    }
    const Fullmmmm = (data) => {
        setupdatedata1(data)


    }
    const ApyFun = async (value) => {
        setupdate(value)
        let result = await apyAmount(value);
        setMyApy(result);
    };

    const getDepositData = () => {
        // setMainLoader(true);
        // let acc = JSON.stringify(account);
        var data = JSON.stringify({
            query: `query MyQuery {
                stakeds(orderBy: amount, orderDirection: desc, first: 20) {
                    amount
                    blockNumber
                    blockTimestamp
                    releaseTime
                    user
                  }
              }`,
        });
        var config = {
            method: "post",
            url: Environment.graph,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        axios(config)
            .then(function (response) {
                console.log("graph",response)
                setStakedData(response?.data?.data?.stakeds);
                // setMainLoader(false);
            })
            .catch(function (error) {
                // setMainLoader(false);
            });
    };

    useEffect(() => {
        // if (account) {
        ApyFun(update);
        
        // }
    }, [update]);

    useEffect(() => {
        twentyFive()
    }, [balance]);

    useEffect(() => {
       getDepositData()
    }, []);


    const opeeennn = () => {
        if(account && balance > 0){
        window.$('#exampleModal').modal('show')
        }else{
            swal("Error!!!", `No Token To Stake`, "error",)
        }
    }

    return (
        <>
            <Backdrop className="loader" sx={{ color: "#fff" }} open={opens}>
                <img
                    src="/assestmeta/Eloin.svg"
                    alt=""
                    className="img-fluid shdshhgdss"
                />
            </Backdrop>

            <section className="stacking">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-10 col-lg-11 m-auto hdehdvh">
                            <div className="stakingmain">
                                <div className="searcjpper">
                                    <div className="left">
                                        <div className="finished">
                                            {/* <button className='but1'>Live</button> */}
                                            {/* <button className='but2'>Finished</button> */}
                                        </div>
                                        {/* <div className="toggler-main">
                                            <div class="custom-control custom-switch">
                                                <input type="checkbox" class="custom-control-input" id="customSwitches" />
                                                <label class="custom-control-label" for="customSwitches">My Staking Pools</label>
                                            </div>
                                        </div> */}

                                    </div>
                                    {/* <div className="right">
                                        <div className="inputxx">
                                            <input class="form-control mr-sm-2" type="search" placeholder="Search by pool name" aria-label="Search" />
                                            <img src="\assestmeta\search-normal.svg" alt="" className="img-fluid shbdhd" />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="stackingdown">
                                    <div className="metifistak">
                                        <div className='left'>
                                            <div className="imhd">
                                                <img src="\assestmeta\Eloin.svg" alt="" className="img-fluid shbdhd sggg" />
                                                <div className="textss">
                                                    <h3>Eloin Staking Pool </h3>
                                                    {/* <h5>With IGO</h5> */}
                                                </div>
                                            </div>
                                            <div className="rihgtsss">
                                                <div className="upper">
                                                    <div className="inner">
                                                        <div className="iconsss">
                                                            <img src="\assestmeta\sta15.svg" alt="" className="img-fluid shbdhd" />
                                                            <h6>Rookie</h6>
                                                        </div>
                                                        <h3>{apy[0]?.lockDays} Days</h3>
                                                    </div>
                                                </div>
                                                <div className="upper">
                                                    <div className="inner">
                                                        <div className="iconsss">
                                                            <img src="\assestmeta\sta12.svg" alt="" className="img-fluid shbdhd" />
                                                            <h6>Pro</h6>
                                                        </div>
                                                        <h3>{apy[1]?.lockDays} Days</h3>
                                                    </div>
                                                </div>
                                                <div className="upper">
                                                    <div className="inner">
                                                        <div className="iconsss">
                                                            <img src="\assestmeta\sta14.svg" alt="" className="img-fluid shbdhd" />
                                                            <h6>Elite</h6>
                                                        </div>
                                                        <h3>{apy[2]?.lockDays} Days</h3>
                                                    </div>
                                                </div>
                                                <div className="upper">
                                                    <div className="inner">
                                                        <div className="iconsss">
                                                            <img src="\assestmeta\sta13.svg" alt="" className="img-fluid shbdhd" />
                                                            <h6>Legend</h6>
                                                        </div>
                                                        <h3>{apy[3]?.lockDays} Days</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='right'>
                                            <div className="mainsss">
                                                <h4>Details <i class="fas fa-chevron-up"></i></h4>
                                            </div>
                                        </div> */}
                                    </div>
                                    <h5 className="alertss">Note: NEVER transfer coin/token directly to smart contract. All transactions need to go through Eloin's Portal.</h5>
                                    <div className="border-djds">
                                        <div className="upper-vlck">
                                            <div className="left-side">
                                                <div className="left">
                                                    <h6>Total pool amount</h6>
                                                    {/* <h6>Start time join</h6>
                                                    <h6>End time join</h6> */}
                                                </div>
                                                <div className="right">
                                                    <h6>{totalStakeAmount ? totalStakeAmount : 0} Eloin</h6>
                                                    {/* <h6>2021-09-15 22:00</h6>
                                                    <h6>2023-09-29 22:00</h6> */}
                                                </div>
                                            </div>
                                            <div className="right-side">
                                                <h6>Balance</h6>
                                                <h5>{balance ? parseFloat(balance).toFixed(2) : 0} Eloin</h5>
                                                {/* <h5>0 Eloin</h5> */}
                                                <div className="buttonssss">
                                                    <button className="hg" onClick={opeeennn}>
                                                        Stake
                                                    </button>
                                                    {/* <button className="gg">
                                                        Unstake
                                                    </button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="live">
                                        <div className="buttonssdk">
                                            <button className="bn">Realtime</button>
                                            {/* <button className="nn">Snapshot</button> */}
                                        </div>
                                        <div class="textsssss">
                                            <h3>Legendary Ranking Realtime</h3>
                                            <h4>Details <i class="fas fa-chevron-up"></i></h4>
                                        </div>
                                        <div className="tbaless">
                                            <div class="table-responsive shbshbcs">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">No</th>
                                                            <th scope="col">Wallet Address</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Last Time Staked</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {stakedData.map((elem,index)=>{
                                                        let date = new Date(elem?.blockTimestamp * 1000);
                                                       let datee= date?.toLocaleString();
                                                        return(
                                                            <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta6.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>{index + 1}</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                            {elem?.user}
                                                            </td>
                                                            <td>
                                                                {elem?.amount / 10**18}
                                                            </td>
                                                            <td>
                                                                {datee}
                                                            </td>
                                                        </tr>  
                                                        )
                                                    })}

                                                    </tbody>
                                                </table>
                                                <div className="newoness">
                                                    <h5>Total Eloin:</h5>
                                                    <h5>{totalStakeAmount ? totalStakeAmount : 0}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="finished">
                                        <div className="buttonssdk">
                                            <button className="bn">Realtime</button>
                                            <button className="nn">Snapshot</button>
                                        </div>
                                        <div class="textsssss">
                                            <h3>Legendary Ranking Realtime</h3>
                                            <h4>Details <i class="fas fa-chevron-up"></i></h4>
                                        </div>
                                        <div className="tbaless">
                                            <div class="table-responsive shbshbcs">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">No</th>
                                                            <th scope="col">Wallet Address</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Last Time Staked</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta6.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>1</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta7.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>2</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta8.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>3</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta6.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>4</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta7.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>5</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta8.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>6</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta6.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>7</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta7.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>8</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div class="imgssss">
                                                                    <img src="\assestmeta\sta8.svg" alt="" className="img-fluid shbdhd" />
                                                                    <div className="ssnsdjn">
                                                                        <h5>9</h5>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                0xAaecec7Cc0b8**********4d32E86f5
                                                            </td>
                                                            <td>
                                                                4,399.732
                                                            </td>
                                                            <td>
                                                                04:56, 25 December 2021 (GMT+05:00)
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <div className="newoness">
                                                    <h5>Total Eloin</h5>
                                                    <h5>27,443.798</h5>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                 */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="modal fade untake-modalsss" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Stake</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body jsbdjwdb">
                            <div className="untake-mpdal">
                                <div className="untakk">
                                    <div className="left">
                                        <h5 className='ddfdfdfd'>Token</h5>
                                        <h5 className='ddfdfdfd'>Balance</h5>
                                        <h5 className='ddfdfdfd'>APY</h5>
                                        {/* <h5 className='ddfdfdfd'>Current Profit</h5> */}
                                    </div>
                                    <div className="right">
                                        <h5 className='uiiuyuuyuy'>Eloin</h5>
                                        <h5 className='uiiuyuuyuy'>{parseFloat(balance).toFixed(2)}</h5>
                                        <h5 className='uiiuyuuyuy'>{myApy ? myApy : 0}%</h5>
                                        {/* <h5 className='uiiuyuuyuy'>100 Token</h5> */}
                                    </div>
                                </div>
                                <div className="inpiuttss">
                                    {allowance <= 0 || allowance < stackbalance ? (
                                        <div className="approves-token text-center">
                                            <button
                                                className="shvdsj"
                                                onClick={Approve_Fun}
                                            >
                                                Approved Token
                                            </button>
                                        </div>
                                    ) : (
                                        <div>
                                            {" "}
                                            {allowance > 0 && (
                                                <div className="amount-div-main">
                                                    <div className="amount-div">
                                                        <h5>Stake Amount</h5>
                                                        <div className="inputMax">
                                                            <input
                                                                placeholder="0"
                                                                value={stackbalance}
                                                                onChange={handleInput}
                                                                type="number"
                                                                class="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="balanceOf">
                                                        <p>
                                                            Balance: <span>{balance}</span>
                                                        </p>
                                                    </div> */}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {/* <input type="number" class="form-control" placeholder="0" id="exampleInput" /> */}
                                </div>
                                <div className="proggrd">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" style={{ width: `${totalpercentage}%` }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <h5>({totalpercentage ? totalpercentage : 0}%)</h5>
                                </div>
                                <div className='selectpercentage mt-4'>
                                    <h1>Select Percentage Value</h1>
                                    <div className="main-percent">
                                        <div className={updatedata == 'a' ? "hhh" : "hh"} onClick={() => twentyFive('a')}>
                                            <h5>25%</h5>
                                        </div>
                                        <div className={updatedata == 'b' ? "hhh mr-2 ml-2" : "hh mr-2 ml-2"} onClick={() => fifty('b')}>
                                            <h5>50%</h5>
                                        </div>
                                        <div className={updatedata == 'c' ? "hhh mr-2" : "hh mr-2"} onClick={() => seventyFive('c')}>
                                            <h5>75%</h5>
                                        </div>
                                        <div className={updatedata == 'd' ? "hhh" : "hh"} onClick={() => Full('d')}>
                                            <h5>100%</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='selectpercentage'>
                                    <h1>Select TimePeriod</h1>
                                    <div className="main-percent">
                                        <div className={updatedata1 == 'a' ? "hhh" : "hh"} onClick={() => { Fullm('a'); ApyFun(0) }} >
                                            <h5>{apy[0]?.lockDays} Days</h5>
                                        </div>
                                        <div className={updatedata1 == 'b' ? "hhh mr-2 ml-2" : "hh mr-2 ml-2"} onClick={() => { Fullmm('b'); ApyFun(1) }} >
                                            <h5>{apy[1]?.lockDays} Days</h5>
                                        </div>
                                        <div className={updatedata1 == 'c' ? "hhh mr-2" : "hh mr-2"} onClick={() => { Fullmmm('c'); ApyFun(2) }}>
                                            <h5>{apy[2]?.lockDays} Days</h5>
                                        </div>
                                        <div className={updatedata1 == 'd' ? "hhh" : "hh"} onClick={() => { Fullmmmm('d'); ApyFun(3) }}>
                                            <h5>{apy[3]?.lockDays} Days</h5>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='reurenss'>
                                    <h4>APY</h4>
                                    <h5>5%</h5>
                                </div>
                                <div className='reurenss mb-4'>
                                    <h4>Total Returns</h4>
                                    <h5>100 Token</h5>
                                </div> */}
                                {allowance > 0 && allowance >= stackbalance &&
                                    <button className="shvdsj" onClick={Stake_token}>Stake</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Stacking;
