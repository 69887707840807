import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import './navbar.scss';
import { HashLink } from 'react-router-hash-link';
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
const Navbar = () => {
    const { account } = useWeb3React();
    console.log("account", account)
    const { login, logout } = useAuth();

    const connectMetaMask = async () => {
        try {
            //   localStorage.setItem("injected", "injected");
            if (account) {
                await logout();
                localStorage.removeItem('connectorId')
                localStorage.removeItem('flag')
            } else {
                localStorage.setItem("connectorId", "injected");
                localStorage.setItem("flag", "true");
                login("injected");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const disconnect = () => {
        logout();
        localStorage.removeItem('connectorId')
        localStorage.removeItem('flag')

        // window.location.reload()
    }
    return (
        <>
            {/* <SideDrawer show={sidetoggle} click={() => setsidetoggle(true)} /> */}
            {/* <Backdrop show={sidetoggle} click={() => setsidetoggle(false)} /> */}
            <section className="main-navbar">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-10 col-lg-11 m-auto">
                            <nav className="navbar ptb20 navbar-expand-xl">
                                <NavLink to="/" className="navbar-brand">
                                    {/* <div className="imhd"> */}
                                        <img src="\assestmeta\LeocornEloin.png" alt="" className="bbbb" />
                                    {/* </div> */}

                                </NavLink>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span> <img src="\bluemoon-nft\hot-bid\navbar-m.png" alt="" className="img-fluid" /></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav mr-auto">
                                        <li class="nav-item active">
                                            {/* <HashLink to="#ff" class="nav-link " > Features <span class="sr-only">(current)</span></HashLink> */}
                                        </li>
                                        <li class="nav-item ">
                                            {/* <HashLink to="#gg" class="nav-link " >About </HashLink> */}
                                        </li>
                                        <li class="nav-item">
                                            {/* <HashLink to="#hh" class="nav-link " >Partnership  </HashLink> */}
                                        </li>
                                        <li class="nav-item">
                                            {/* <HashLink to="#ss" class="nav-link " >MetaFi Ecosystem </HashLink> */}
                                        </li>
                                        {/* <li class="nav-item active">
                                            <a class="nav-link " href="sds">Home <span class="sr-only">(current)</span></a>
                                        </li> */}
                                        {/* <li class="nav-item">
                                            <Link class="nav-link" to="/TokenSale">Tokan Sale</Link>
                                        </li> */}
                                        <li class="nav-item">
                                            <a href="/" class="nav-link"> Staking</a>
                                            {/* <Link to="/" class="nav-link"  >Staking  <span class="sr-only">(current)</span></Link> */}
                                        </li>
                                        <li class="nav-item">
                                            {/* <a href="/unstaking" class="nav-link"> UnStaking</a> */}
                                            <Link to="/unstaking" class="nav-link" >UnStaking</Link>
                                        </li>
                                        {/* <li class="nav-item">
                                            <Link to="/unstaking" class="nav-link" >{account}</Link>
                                        </li> */}
                                        {/* <li class="nav-item">
                                        <HashLink to="/staking" class="nav-link " >Staking </HashLink>
                                        </li> */}

                                        {/* <li class="nav-item">
                                        <Link to="/upcomingproject" class="nav-link" >UpComming Projects</Link>
                                        </li> */}
                                        {/* <li class="nav-item">
                                        <Link to="/allpools" class="nav-link"> All Pools</Link>
                                        </li> */}
                                        {/* <li>
                                            <form className="form-inline formm-mm my-2 my-lg-0">
                                                <input className="form-control input-bbb mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                                                <button className="btn search-barss  my-2 my-sm-0" type="submit" ><img src="\bluemoon-nft\hot-bid\search-bar-1.svg" alt="" className="img-fluid" /></button>
                                            </form>
                                        </li> */}
                                    </ul>

                                    {/* <form className="form-inline my-2 my-lg-0"> */}
                                    <div className="form-inline my-2 my-lg-0">
                                        {account ? <button className="nav-buttoo" onClick={connectMetaMask} >Disconnect</button> : <button className="nav-buttoo" onClick={connectMetaMask}>Connect Wallet</button>}
                                    </div>
                                    {/* <a className="nav-buttoo" href="https://docs.google.com/forms/d/e/1FAIpQLSdRkAPW_zLgEFBqNvASjgPBqAYozeAkcG1tkVOdr5GLs3la8w/viewform?usp=sf_link" target="_blank">Apply for IDO</a> */}
                                    {/* </form> */}
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Navbar;
