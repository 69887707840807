import './App.scss';
import React from 'react';
import Landing from './components/landing/Landing.js';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Assets/pilot/PilotCommandCondensed-vmepL.woff'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './main.js'
import Footer from './components/landing/footer/Footer.js';
import Navbar from './components/landing/header/Navbar.js';
import TokenSale from './components/tokensale/Tokensale';
import Allpool from './components/tokensale/Allpool';
import Stacking from './components/stacking/Stacking';
import UnStacking from './components/unstacking/Unstacking'
import UpcomingP from './components/upcomingproject/Upcoming';
import useEagerConnect from './hooks/useEagerConnect';
import TeamOur from './components/Team/Team';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  useEagerConnect();
  return (
    <>
        <Router>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Navbar/>
        <Switch>
          {/* <Route exact path="/staking">
            <Redirect to="/" />
          </Route> */}
          {/* <Route exact path='/' component={Landing} /> */}
          {/* <Route exact path='/TokenSale' component={TokenSale} />
          <Route exact path='/allpools' component={Allpool} /> */}
          <Route exact path='/' component={Stacking} />
          <Route exact path='/unstaking' component={UnStacking} />
          {/* <Route exact path='/upcomingproject' component={UpcomingP} />
          <Route exact path='/ourteam' component={TeamOur} />
          <Route exact path='/adviser' component={TeamOur} /> */}
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
