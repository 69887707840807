// import logo from './logo.svg';
import '../../App.scss';
import Partnership from '../partner/Partner.js';
import PerformanceM from '../performance/Performance.js';
import Stacking from '../stacking/Stacking';
import Footer from './footer/Footer';
import Navbar from './header/Navbar';


import Banner from './main-banner/Banner.js';
import Tokensale from './tokensale/Token.js';


function Landing() {
  return (
    <>
      <Navbar />
      {/* <Stacking /> */}
      {/* <Banner /> */}
      {/* <Tokensale/>
      <Partnership />
      <PerformanceM/> */}
      <Footer />
    </>
  );
}

export default Landing;